input[type=file] {
  font-size: 12px;
}

input[type=file]::file-selector-button {
    font-size: 12px;
    margin-right: 4px;
    border: none;
    background: #309964;
    padding: 5px 10px;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #349606;
  }
